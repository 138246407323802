import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="text-center">
 <h2 className="text-xl md:text-4xl text-black font-bold">Vipps en donasjon til Trailguide</h2>
  <a href="https://qr.vipps.no/28/2/05/031/00qn6ilap">
    <Image src="website/vipps-qr-donation.png" className="w-3/4 md:w-1/2 max-w-lg mt-4 mx-auto" mdxType="Image" />
  </a>
  <div className="text-center text-4xl font-bold">571734</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      